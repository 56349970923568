<template>
<div>
  <ejs-dialog
    ref="regNoBatchInputPopup"
    header="주민등록번호 일괄입력"
    :allowDragging="true"
    :showCloseIcon="true"
    width="600"
    height="450"
    :enableResize="false"
    :isModal="true"
    @close="onClose"
  >
    <ejs-grid-wrapper
      ref="regNoBatchInputGrid"
      v-bind="gridOptions"
      @cellSaved="onCellSaved"
    />
    <div class="windowFooter">
      <ul class="button">
        <li class="close">
          <erp-button
              button-div="SAVE"
              @click.native="onSave"
          >
            적용
          </erp-button>
          <erp-button
              button-div="CLOSE"
              @click.native="onClose">
            닫기
          </erp-button>
        </li>
      </ul>
    </div>
  </ejs-dialog>
</div>
</template>
<script>
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, Filter, ForeignKey, Resize, Page} from "@syncfusion/ej2-vue-grids";
import {regNoFormatter} from "@/utils/formatter";
import ErpButton from "@/components/button/ErpButton.vue";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "RegNoBatchInputPopup",
  components: {ErpButton, EjsGridWrapper},
  mixins: [MessagePopupDialogMixin],
  data() {
    return {
      dataSource: [],
    };
  },
  computed: {
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          ForeignKey,
          Edit,
          Page
        ],
        dataSource: this.dataSource,
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        height: 288,
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        columns: [
          {
            field: "memberNo",
            headerText: "회원번호",
            type: "string",
            width: 60,
            allowEditing: false,
          },
          {
            field: "giverOrTaker",
            headerText: "양수/양도",
            type: "string",
            width: 60,
          },
          {
            field: "memberName",
            headerText: "회원명",
            type: "string",
            width: 60,
            allowEditing: false,
          },
          {
            field: "regNum",
            headerText: "주민등록번호",
            type: "string",
            width: 90,
            valueAccessor: (field, data) => {
              return regNoFormatter(data[field]);
            }
          },
        ]
      };
    },
  },
  methods: {
    show(dataSource) {
      dataSource.forEach((data) => {
        const isGive = data.giveCprDiv === "FIT";
        const isTake = data.takeCprDiv === "FIT";
        const hasGiveRegNum = isGive && data.giveRegNum;
        const hasTakeRegNum = isTake && data.takeRegNum;

        if(isGive && !hasGiveRegNum) {
          this.dataSource.push({
            memberName: data.giveMemberName,
            regNum: data.giveRegNum,
            memberNo: data.giveMemberNo,
            renameRequestId: data.renameRequestId,
            isGive,
            giverOrTaker: '양도자'
          });
        }
        if(isTake && !hasTakeRegNum) {
          this.dataSource.push({
            memberName: data.takeMemberName,
            regNum: data.takeRegNum,
            memberNo: data.takeMemberNo,
            renameRequestId: data.renameRequestId,
            isTake,
            giverOrTaker: '양수자'
          });
        }
      });
    },
    onClose() {
      this.$emit("close");
    },
    onSave() {
      const registerData = this.dataSource.filter(data => data.regNum);
      this.dataSource = this.dataSource.filter(data => !data.regNum);
      this.$emit("save", registerData);
    },
    onCellSaved(args) {
      const isRegNumValid = regNoFormatter(args.value);
      if(!isRegNumValid) {
        args.value = null;
        this.errorToast("주민등록번호 형식이 올바르지 않습니다.");
      }
      this.dataSource = this.dataSource.map((data) => {
        const isSameRenameRequestId = data.renameRequestId === args.rowData.renameRequestId;
        const isSameGiverOrTaker = data.giverOrTaker === args.rowData.giverOrTaker;
        if(isSameRenameRequestId && isSameGiverOrTaker) {
          data.regNum = args.value;
        }
        return data;
      });
    }
  }
};
</script>


<style scoped lang="scss">
.windowFooter {
  padding: 4px 0 !important;
}
.button {
  li {
    display: flex;
    justify-content: end;
    gap: 3px;
  }
}
</style>