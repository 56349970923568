<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item">
                <input-number
                    v-model="searchOptions.year"
                    :allowDot="false"
                    :allowMinus="false"
                    :displayComma="false"
                    :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">분기</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  :dataSource="quaterOptions"
                  :fields="optionsFields"
                  v-model="searchOptions.quarter"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">변경구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    :dataSource="renameDivOptions"
                    :fields="optionsFields"
                    v-model="searchOptions.renameDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">회원명/회원번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                    type="lookup-condition"
                    v-model="searchOptions.searchMemberNameNo"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              ignore
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">명의변경(전산매체신고)</div>
              <div class="header-caption">[{{ dataSource.length }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="file-make">
                  <erp-button
                      button-div="SAVE"
                      ignore
                      :disabled="!dataSource.length"
                      @click.native="onOpenRegNumBatchInputPopup"
                  >
                    주민번호 일괄입력
                  </erp-button>
                </li>
                <li class="file-make">
                  <erp-button
                      button-div="SAVE"
                      ignore
                      @click.native="onFileMakeButtonClicked"
                  >
                    신고파일생성
                  </erp-button>
                </li>
                <li class="file-make">
                  <erp-button
                      button-div="SAVE"
                      @click.native="onSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="nameChangeGrid"
                v-bind="gridOptions"
                :dataSource="dataSource"
                @queryCellInfo="onQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <report-file-generator-popup
        v-if="isReportFileGeneratorPopup"
        :searchOptions="searchOptions"
        @close="isReportFileGeneratorPopup = false"
    />
    <reg-no-batch-input-popup
        ref="regNoBatchInputPopup"
        v-if="isRegNoBatchInputPopupOpened"
        @save="onSaveRegNumBatchInput"
        @close="onCloseRegNoBatchInputPopup"
    />
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import {commonCodesGetCommonCode, commonCodesGetCommonCodeByAttrb} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";
import {ExcelExport, Filter, ForeignKey, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import InputText from "@/components/common/text/InputText";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
// import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import {
  bizNoFormatter,
  regNoFormatter,
  // removeHyphensFromColumns
} from "@/utils/formatter";
import ReportFileGeneratorPopup from "@/views/transfer-of-name/popup/ReportFileGeneratorPopup.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import RegNoBatchInputPopup from "@/views/transfer-of-name/popup/RegNoBatchInputPopup.vue";

export default {
  name: "NameChangeReportByComputerizedMedium",
  components: {
    RegNoBatchInputPopup,
    InputNumber,
    InputText,
    ErpButton,
    EjsGridWrapper,
    ReportFileGeneratorPopup
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        year: moment().year(),
        quarter: this._getCurrentQuarter(),
        renameDiv: "",
        searchMemberNameNo: null,
      },
      dataSource: [],
      optionsFields: { text: "comName", value: "comCode" },
      quaterOptions: [],
      renameDivOptions: [],
      isReportFileGeneratorPopup: false,
      isRegNoBatchInputPopupOpened: false,
      membershipTransferReportBasicRequest: {},
      editedRow: [],
      gridOptions: {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Edit
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: false,
        noColumnWidth: 40,
        columns: [
          {
            field: "renameDiv",
            headerText: "명의변경구분",
            type: "string",
            width: 90,
            allowEditing: false,
            valueAccessor: (field, data) => this.renameDivOptions.find(item => item.comCode === data[field]).comName,
          },
          {
            headerText: "현회원",
            columns: [
              {
                field: "giveMemberNo",
                width: 90,
                headerText: "회원번호",
                allowEditing: false,
              },
              {
                field: "giveMemberName",
                width: 90,
                headerText: "성명/법인명",
                allowEditing: false,
              },
              {
                field: "giveRegNum",
                width: 90,
                headerText: "주민번호/사업자번호",
                allowEditing() {
                  return this.renameDiv !== 'NEW';
                } ,
                minLength: 13,
                valueAccessor: (field, data) => {
                  const isNew = data.renameDiv === "NEW";
                  const isCPR = data.giveCprDiv === "CPR";

                  if(isNew || !data[field]) { return; }
                  return isCPR ? bizNoFormatter(data[field]) : regNoFormatter(data[field]);
                }
              },
              {
                field: "giveAddr",
                width: 90,
                headerText: "주소",
                allowEditing: false,
                valueAccessor: (field, {giveAddr1, giveAddr2}) => `${giveAddr1 ?? ""} ${giveAddr2 ?? ""}`,
              }
            ]
          },
          {
            field: "acqsDate",
            headerText: "입회일자",
            width: 90,
            allowEditing: false,
            valueAccessor: (field, data) =>
                moment(data[field]).format('YYYY-MM-DD')
          },
          {
            headerText: "변경회원",
            columns: [
              {
                field: "takeMemberNo",
                width: 90,
                headerText: "회원번호",
                allowEditing: false,
              },
              {
                field: "takeMemberName",
                width: 90,
                headerText: "성명/법인명",
                allowEditing: false,
              },
              {
                field: "takeRegNum",
                width: 90,
                headerText: "주민번호/사업자번호",
                minLength: 13,
                allowEditing(field, data) {
                  return data.renameDiv !== 'OUT';
                },
                valueAccessor: (field, data) => {
                  const isOUT = data.renameDiv === 'OUT';
                  const isCPR = data.takeCprDiv === "CPR";

                  if(isOUT || !data[field]) { return; }
                  return isCPR ? bizNoFormatter(data[field]) : regNoFormatter(data[field]);
                }
              },
              {
                field: "takeAddr",
                width: 90,
                headerText: "주소",
                allowEditing: false,
                valueAccessor: (field, {takeAddr1, takeAddr2}) => `${takeAddr1 ?? ""} ${takeAddr2 ?? ""}`,
              },
            ]
          },
          {
            field: "renameDate",
            width: 90,
            headerText: "변경일자",
            allowEditing: false,
          }
        ],
      }
    };
  },
  async created() {
    this.quaterOptions = commonCodesGetCommonCode('STATEMENT_QU', true);
    this.renameDivOptions = commonCodesGetCommonCodeByAttrb('RENAME_DIV','STATEMENT',true);
    this.renameDivOptions.unshift({comCode: '', comName: '전체'});
    this.renameDivOptions.renameDiv = "";
    await this.onViewButtonClicked();
  },
  methods: {
    async onViewButtonClicked() {
      this.membershipTransferReportBasicRequest = {
        statementYear: `${this.searchOptions.year}`,
        statementQu: this.searchOptions.quarter,
      };
      const gridData = await GolfErpAPI.getMembershipChangeReportList(this.searchOptions);
      this.dataSource = gridData.map((item) => {
        item.takeRegNum = item.takeCprDiv === 'CPR' ? item.takeBizNo : item.takeRegNum;
        item.giveRegNum = item.giveCprDiv === 'CPR' ? item.giveBizNo : item.giveRegNum;
        return item;
      });

    },
    async onSaveButtonClicked() {
      try {
        const allGridData = this.$refs.nameChangeGrid.getBatchCurrentViewRecords();
        const isValid = this._isGridValid(allGridData);
        if (!isValid) { return; }

        const gridData = this._joinAddr(allGridData);

        isValid && await GolfErpAPI.saveMembershipChangeReportList({
          membershipTransferReportBasicRequest: this.membershipTransferReportBasicRequest,
          membershipTransferReportDetailRequestList: gridData,
        });
        this.infoToast('저장되었습니다.');
      } catch (e) {
        console.error(e);
      }
    },
    _joinAddr(gridData) {
      gridData.forEach((item) => {
        const { giveAddr1, giveAddr2, takeAddr1, takeAddr2 } = item;
        if(giveAddr1) {
          item.giveAddr = `${giveAddr1} ${giveAddr2 ?? ""}`.trim();
        }
        if(takeAddr1) {
          item.takeAddr = `${takeAddr1} ${takeAddr2 ?? ""}`.trim();
        }
      });
      return gridData;
    },
    _isGridValid(gridData) {
      for (const row of gridData) {
        const { renameDiv, takeRegNum, giveRegNum } = row;
        const needsTakeRegNum = renameDiv === 'NEW' && !takeRegNum;
        const needsGiveRegNum = renameDiv === 'OUT' && !giveRegNum;
        const needsRegNum = renameDiv !== 'NEW' && renameDiv !== 'OUT' && (!takeRegNum || !giveRegNum);

        if (needsTakeRegNum || needsGiveRegNum || needsRegNum) {
          this.errorToast('주민번호나 사업자번호가 입력되지 않은 곳이 있습니다.');
          return false;
        }
      }
      return true;
    },
    onExcelButtonClicked() {
      if(!this.dataSource.length) {
        this.errorToast('조회된 데이터가 없습니다.');
        return;
      }
      this.$refs.nameChangeGrid.excelExport({ fileName: '명의변경(전산매체신고).xlsx' });
    },
    onFileMakeButtonClicked() {
      this.isReportFileGeneratorPopup = true;
    },
    onQueryCellInfo(args) {
      if (args.column.field === "takeRegNum" || args.column.field === "giveRegNum") {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    _getCurrentQuarter() {
      const month = moment().month() + 1;
      if (month <= 3) { return "QU_1"; }
      if (month <= 6) { return "QU_2"; }
      if (month <= 9) { return "QU_3"; }
      return "QU_4";
    },
    onOpenRegNumBatchInputPopup() {
      this.isRegNoBatchInputPopupOpened = true;
      this.$nextTick(() => {
        this.$refs.regNoBatchInputPopup.show(this.dataSource);
      });
    },
    onCloseRegNoBatchInputPopup() {
      this.isRegNoBatchInputPopupOpened = false;
    },
    onSaveRegNumBatchInput(registData) {
      this.dataSource.forEach(item => {
        registData.forEach(registItem => {
          if(item.renameRequestId === registItem.renameRequestId) {
            if(registItem.isGive) {
              item.giveRegNum = registItem.regNum;
            }
            if(registItem.isTake) {
              item.takeRegNum = registItem.regNum;
            }
          }
        });
      });
    }
  },
};
</script>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>
