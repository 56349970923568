<template>
  <div>
    <ejs-dialog
      ref="reportFileGeneratorPopup"
      header="신고파일생성"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1000"
      height="auto"
      :enableResize="false"
      :isModal="true"
      @close="$emit('close')"
    >
      <div class="window couponKindDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <ul class="lookup-condition">
                <li class="field">
                  <div class="header-title">신고파일생성</div>
                </li>
              </ul>
            </div>
            <div class="header-right">
              <ul class="lookup-condition">
                <li class="field">
                  <div class="title required">년도</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        ref="input-year"
                        name="taxYear"
                        v-model="taxYear"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">분기</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                          :dataSource="quaterOptions"
                          :fields="optionsFields"
                          v-model="quarter"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="lookup-lookup">
                <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="onSearch"
                >
                  조회
                </erp-button>
              </div>
            </div>
          </div>
          <div id="section-body" class="section-body">
            <article class="body-article">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="nameChangeGrid"
                    v-bind="gridOptions"
                    :dataSource="dataSource"
                    :isNOColumnDisplay="false"
                    :aggregates="aggregates"
                    @queryCellInfo="onQueryCellInfo"
                />
              </div>
            </article>
          </div>
        </section>
      </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="lookup-condition">
            <li class="field">
              <div class="title">제출일자</div>
              <ul class="content">
                <li class="item">
                  <input-date
                      type="lookup-condition"
                      v-model="submitDate"
                  />
                </li>
              </ul>
            </li>
          </ul>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                :is-shortcut-button="true"
                @click.native="onSave"
              >
                저장
              </erp-button>
            </li>
            <li class="create">
              <erp-button
                button-div="FILE"
                :is-shortcut-button="true"
                @click.native="onCreate"
              >
                신고파일생성
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="$emit('close')">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputDate from "@/components/common/datetime/InputDate.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import cloneDeep from "lodash/cloneDeep";
import iconv from "iconv-lite";

export default {
  components: {EjsGridWrapper, InputDate, ErpButton, InputText},
  mixins: [confirmDialogMixin],
  props: {
    searchOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      recordId: null,
      taxYear: this.searchOptions.year,
      quarter: this.searchOptions.quarter,
      searchedOptions: {
        taxYear: null,
        quarter: null,
      },
      optionsFields: { text: "comName", value: "comCode" },
      quaterOptions: [],
      submitDate: null,
      dataSource: [],
      aggregates: [
        {
          columns: [
            {
              field: 'lastQuarter',
              aggregationType: 'TotalSum',
            },
            {
              field: 'renameMemberDivName',
              aggregationType: 'TotalCaption',
              customAggregate: "합계",
            }
          ]
        }
      ],
      gridOptions: {
        provides: [
          Filter,
          Resize,
          ExcelExport,
          ForeignKey,
          Edit,
          Aggregate
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        columns: [
          {
            field: "renameMemberDivName",
            headerText: "회원구분",
            allowEditing: false,
          },
          {
            field: "beforeQuarter",
            headerText: "분기 초",
            allowEditing: false,
            textAlign: "Right",

          },
          {
            field: "increase",
            headerText: "증가",
            allowEditing: false,
            textAlign: "Right",
          },
          {
            field: "decrease",
            headerText: "감소",
            allowEditing: false,
            textAlign: "Right",
          },
          {
            field: "lastQuarter",
            headerText: "분기 말",
            allowEditing: true,
            textAlign: "Right",
          },
        ],
      },
    };
  },
  created() {
    this.quaterOptions = commonCodesGetCommonCode('STATEMENT_QU', true);
  },
  methods: {
    async onSave() {
      console.log('=== save ===');
      try {
        const batchData  = this.$refs.nameChangeGrid.getBatchCurrentViewRecords();
        const mapping = {
          "일반회원(A)": "norQuarterEnd",
          "특별회원(B)": "spcQuarterEnd",
          "주중회원(C)": "dayQuarterEnd"
        };
        const quarterEndData = batchData.reduce((acc, { lastQuarter, renameMemberDivName }) => {
          const key = mapping[renameMemberDivName];
          if (key) {
            acc[key] += +lastQuarter;
          }
          return acc;
        }, { norQuarterEnd: 0, spcQuarterEnd: 0, dayQuarterEnd: 0 });

        const params = {
          ...quarterEndData,
          id: this.recordId,
          presentnDate: this.searchedOptions.submitDate,
          statementYear: this.searchedOptions.taxYear,
          statementQu: this.searchedOptions.quarter,
        };
        await GolfErpAPI.patchReportBasic(params);
      } catch (e) {
        console.error(e);
      }
    },
    async onCreate() {
      console.log('=== create ===');
      if(!this.recordId) {
        this.errorToast('신고파일 생성을 위한 데이터가 없습니다. 조회를 먼저 해주세요.');
        return;
      }
      if (!(await this.confirm('신고파일 생성 후 모든 신고자료 데이터는 삭제됩니다.'))) {
        return;
      }
      const { tgMembershipStatementA,
        tgMembershipStatementBList
      } = await GolfErpAPI.getDataForReportGeneration({
        id: this.recordId
      });

      const recordA = this.makeRecord(tgMembershipStatementA) + '\n';
      const recordB = tgMembershipStatementBList.map((obj) => this.makeRecord(obj)).join('\n');
      const combineRecord = iconv.encode(recordA + recordB, 'EUC-KR');

      // 파일생성
      const businessRegNumber = tgMembershipStatementA.record9;
      const fileName = `Q${businessRegNumber.slice(0, 7)}.${businessRegNumber.slice(7)}`;
      const blob = new Blob([combineRecord], { type: 'text/plain;charset=EUC-KR' });
      const url = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
      URL.revokeObjectURL(url); // 메모리 해제
    },
    makeRecord(obj) {
      return Object.entries(obj)
          .filter(([key]) => key.startsWith("record"))
          .map(([, value]) => value)
          .join('');
    },
    async onSearch() {
      const { id, presentnDate, membershipTransferCounts } = await GolfErpAPI.getGeneratedReportFiles({
        year: this.taxYear,
        quarter: this.quarter,
      });
      this.recordId = id;
      this.submitDate = presentnDate;
      this.searchedOptions = {
        taxYear: this.taxYear,
        quarter: this.quarter,
        submitDate: presentnDate,
      };

      this.dataSource = cloneDeep(membershipTransferCounts);
    },
    onQueryCellInfo(args) {
      if (args.column.field === "lastQuarter") {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
  },
};
</script>

<style scoped>
.windowFooter {
  display: flex !important;
  justify-content: right;
}
</style>
